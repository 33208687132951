<template>
    <div class="container mt-4" style="text-align: justify;width: 1042px;box-shadow: 0 2px 4px -1px rgb(0 0 0 / 25%);padding: 0 100px 0 100px;">
        <div class="row">
            <div class="col" style="text-align: right;">
                <b-button variant="outline-primary" style="margin-left: 10px;" to="/">Voltar</b-button>
                <b-button variant="outline-primary" @click="toPdf()" style="margin-left: 10px;">To PDF</b-button>
            </div>
        </div>
        <hr>
    
        <b-form-group label="Using options array:" v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="selected_itens"
                :options="itens_visiveis"
                :aria-describedby="ariaDescribedby"
            ></b-form-checkbox-group>
        </b-form-group>
        <div class="row">
            <div class="col-5 mt-1 mb-1">
                <b-form-select v-model="curso_selecionado" :options="['Selecione um Curso']"></b-form-select>
            </div>
            <div class="col-5 mt-1 mb-1">
                <b-form-select v-model="campus_selecionado" :options="['Selecione um Campus']"></b-form-select>
            </div>
        </div>
        <hr>

        <!-- <div id="capa" class="" style="text-align: center; height: 90vh">
            <h4><strong>Plano de curso</strong></h4><br>
            <img src="https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/112010/logo_uninove.png?itok=zhcNlJqL">
            <h1><strong>CURSO TÉCNICO EM {{nome_curso}}</strong></h1><br><br><br><br><br><br>
            <h1><strong>EIXO TECNOLÓGICO: {{eixo_curso}}</strong> </h1><br><br><br><br><br><br>
            <h4><strong>SÃO PAULO</strong></h4><br>
            <h4><strong>2022</strong></h4>
        </div> -->

        <!--<hr class="mb-5 mt-5">-->
       
        <!-- <div id="sumario" class="row">
            <h3>Sumário</h3>
            <ul>
                <strong>Dados Gerais da Instituição</strong>
            </ul>
            <ul>
                <li><strong>Dados Gerais do Curso Técnico de Nível Médio</strong></li>
                <ul>
                    <li>Certificações intermediárias </li>
                    <li>Organização da Oferta</li>
                </ul>
            </ul>
            <ol>

                <li><strong>1. Apresentação e justificativa.</strong>
                    <ol>
                        <li>1.1 Apresentação da Instituição. </li>
                        <li>1.2 Apresentação da unidade de ensino ofertante (Código e-MEC do endereço)</li>
                        <li>1.3 Justificativa para oferta do curso. </li>
                        <li>1.4. Relevância da oferta (evidenciar a necessidade de profissionais com a formação na localidade).</li>
                        <li>1.5. Objetivos do Curso:
                            <ol>
                                <li>1.5.1. Objetivo Geral. </li>
                                <li>1.5.2. Objetivos Específicos.</li>
                            </ol>
                        </li>
                        <li></li>
                        <li></li>
                    </ol>
                </li>

                <li><strong>2. Formas de acesso.</strong>
                    <ol>
                        <li>2.1. Pré-requisitos para acesso ao curso. </li>
                        <li>2.2 Formas de acesso ao curso. </li>
                    </ol>
                </li>

                <li><strong>3. Perfil profissional de conclusão.</strong>
                    <ol>
                        <li>3.1 Habilidades e competências desenvolvidas na formação</li>
                    </ol>
                </li>

                <li><strong>4. Organização Curricular</strong>
                    <ol>
                        <li>4.1. Representação gráfica da organização curricular e do itinerário formativo</li>
                        <li>4.2. Apresentação das Unidades Curriculares
                            <ol>
                                <li>4.2.1. Ementários das Unidades</li>
                            </ol>
                        </li>
                        <li>4.3. Plano de realização do Estágio Curricular</li>
                        <li>4.4. Critérios e Mecanismos de Aproveitamento de Conhecimentos e Experiências Anteriores</li>
                        <li>4.5. Procedimentos, Mecanismos e Gestão da Avaliação do Processo Ensino-Aprendizagem</li>
                        <li>4.6 Critérios de avaliação da aprendizagem
                            <ol>
                                <li>4.6.1 Estratégias de acompanhamento e superação de dificuldades</li>
                                <li>4.6.2 Estratégias de recuperação</li>
                                <li>4.6.3 Promoção/Pendência</li>
                            </ol>
                        </li>
                        <li>4.7. Regras de Trancamento</li>
                        <li>4.8 Descrição do funcionamento dos serviços de apoio psicopedagógico e atendimento educacional especializado</li>
                        <li>4.9 Estágio supervisionado</li>
                    </ol>
                </li>

                <li><strong>5. Instalações e equipamentos</strong>
                    <ol>
                        <li>5.1 Salas de aula</li>
                        <li>5.2 Relação de laboratórios</li>
                        <li>5.3 Materiais, equipamentos, ferramentas e outros insumos para atividades práticas:</li>
                        <li>5.4 Outras instalações</li>
                        <li>5.5 Recursos de acessibilidade</li>
                        <li>5.6 Biblioteca</li>
                        <li>5.7 Acervo bibliográfico do curso</li>
                    </ol>
                </li>

                <li><strong>6. Corpo Docente e Tutorial, Coordenador Pedagógico e Pessoal Técnico-Administrativo</strong>
                    <ol>
                        <li>6.1. Corpo Docente</li>
                        <li>6.2. Corpo Tutorial</li>
                        <li>6.3. Coordenador Pedagógico do Curso</li>
                        <li>6.4. Pessoal Técnico Administrativo</li>
                    </ol>
                </li>


                <li><strong>7. Diplomas e Certificados Expedidos</strong>
                    <ol>
                        <li>7.1 Descrição das informações do diploma a ser emitido</li>
                        <li>7.2 Descrição das informações dos certificados de qualificação profissional</li>
                    </ol>
                </li>


                <li><strong>8. Descrição do Ambiente Virtual de Aprendizagem</strong>
                </li>


                <li><strong>9. Anexos</strong>
                    <ol>
                        <li>9.1 Enxerto do Regimento Interno</li>
                        <li>9.2 Relação dos Polos de apoio presencial</li>
                        <li>9.3 Termo de Responsabilidade e Veracidade</li>
                    </ol>
                </li>              
            </ol>
        </div> -->
         
        <!--  <hr class="mb-5 mt-5">  -->

        <div class="row mb-5 mt-5" style="text-align: center">
            <h1>Proposta Pedagógica do Curso</h1>
            <hr>
        </div>

        <div class="row mb-5 mt-5">
            <h2>1. Apresentação e justificativa.</h2>
            <div class="row mb-5">
                <!-- <h4>1.1 Apresentação da Instituição:</h4> -->
                <richTextInput :showMode="true" forms="uninove_geral" titulo="1.1 Apresentação da Instituição" v-model="formulario_geral.item_1_1" @valorEmitido="log"></richTextInput>
            </div>
            <div class="row mb-5">
                <!-- <h4>1.2 Apresentação da unidade de ensino ofertante (Código e-MEC do endereço):</h4> -->
                <richTextInput :showMode="true" forms="uninove_geral" titulo="1.1 Apresentação da Instituição" v-model="formulario_geral.item_1_2" @valorEmitido="log"></richTextInput>
            </div>
        </div>
        
        <!-- 1.3 Justificativa para oferta do curso. -->
        <div class="row mb-5 mt-5">
            <!-- <h4>1.3 Justificativa para oferta do curso.</h4>
            <hr> -->
            <itemInput :showMode="true" titulo="1.3 Justificativa para oferta do curso" v-model="formulario_especifico.item_1_3" @valorEmitido="log"></itemInput>
        </div>

        <!-- 1.4. Relevância da oferta (evidenciar a necessidade de profissionais com a formação na localidade). -->
        <div class="row mb-5 mt-5">
            <h5>1.4. Relevância da oferta (evidenciar a necessidade de profissionais com a formação na localidade).</h5>
            <hr>
            <p>{{formulario_especifico.item_1_4}}</p>
        </div>
        
        <!-- 1.5. Objetivos do Curso: -->
        <div class="row mb-5 mt-5">
            <h5>1.5. Objetivos do Curso:</h5>
            <hr>
        </div>

        <!-- 1.5.1. Objetivo Geral. -->
        <div class="row mb-5">
            <h5>1.5.1. Objetivo Geral.</h5>
            <hr>
            <p>{{formulario_especifico.item_1_5_1}}</p>
        </div>

        <!-- 1.5.2. Objetivos Específicos. -->
        <div class="row mb-5 mt-5">
            <h5>1.5.2. Objetivos Específicos.</h5>
            <hr>
            <p>{{formulario_especifico.item_1_5_2}}</p>
        </div>

        <!-- 2. Formas de acesso. -->
        <div class="row mb-5 mt-5">
            <h2>2. Formas de acesso.</h2>
        </div>

        <!-- 2.1. Pré-requisitos para acesso ao curso. -->
        <div class="row mb-5 mt-5">
            <h5> 2.1. Pré-requisitos para acesso ao curso.</h5>
            <hr>
            <richTextInput :showMode="true" forms="uninove_geral" titulo="2.1. Pré-requisitos para acesso ao curso" @valorEmitido="log"></richTextInput>
        </div>

        <!-- 2.2 Formas de acesso ao curso. -->
        <div class="row mb-5 mt-5">
            <h5>2.2 Formas de acesso ao curso.</h5>
            <hr>
            <richTextInput :showMode="true" forms="uninove_geral" code="item_2_2" titulo="2.2 Formas de acesso ao curso" v-model="formulario_geral.item_2_2" @valorEmitido="log"></richTextInput>
        </div>

        <!-- 3. Perfil profissional de conclusão. -->
        <div class="row mb-5 mt-5">
            <h2>3. Perfil profissional de conclusão.</h2>
        </div>

        <!-- 3.1 Habilidades e competências desenvolvidas na formação -->
        <div class="row mb-5 mt-5">
            <richTextInput :showMode="true" forms="formulario_especifico" code="item_3_1" titulo="3.1 Habilidades e competências desenvolvidas na formação" v-model="formulario_especifico.item_3_1" @valorEmitido="log"></richTextInput>
        </div>

        <!-- 4. Organização Curricular -->
        <div class="row mb-5 mt-5">
            <h2>4. Organização Curricular.</h2>
        </div>

        <!-- 4.1. Representação gráfica da organização curricular e do itinerário formativo -->
        <div class="row mb-5">
            <h5>4.1. Representação gráfica da organização curricular e do itinerário formativo.</h5>
            <hr>
            <uploadImgInput :showMode="true" :curso_id="$route.params.doc" item="item_4_1"></uploadImgInput>
        </div>

        <!-- 4.2. Apresentação das Unidades Curriculares -->
        <div class="row mb-5 ">
            <h4>4.2. Apresentação das Unidades Curriculares.</h4>
            <hr>
        </div>

        <!-- 4.2.1. Ementários das Unidades -->
        <div class="row mb-5 mt-5">
            <h5>4.2.1. Ementários das Unidades.</h5>
            <hr>
            <div v-for="item in formulario_especifico.item_4_2_1" :key="item.id" class="row mt-2 mb-4">
                <b-table-simple :fixed="false" bordered>
                    <b-thead head-variant="light">
                        <b-tr>
                            <b-th colspan="6">UNIDADE CURRICULAR: {{item.nome_unidade}}</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-td colspan="6"><strong> {{item.semestre}}° SEMESTRE</strong></b-td>
                        </b-tr>
                    </b-tbody>
                    <b-tbody>
                        <b-tr>
                            <b-td colspan="6"><strong>CARGA HORÁRIA: {{item.carga_horaria}} horas</strong></b-td>
                        </b-tr>
                    </b-tbody>
                    <b-thead head-variant="light">
                        <b-tr>
                            <b-th colspan="6">EMENTA:</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-td colspan="6">{{item.ementa}}</b-td>
                        </b-tr>
                    </b-tbody>
                    <b-thead head-variant="light">
                        <b-tr>
                            <b-th colspan="6">COMPETÊNCIAS: </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-td colspan="6">
                                <br>
                                <ol>
                                    <li v-for="competencia in item.competencias" :key="competencia.id">
                                        <strong>{{competencia.nome}}</strong>
                                        <ul>
                                            <li><strong>Conhecimento: </strong>{{competencia.conhecimento}}</li>
                                            <li><strong>Habilidade: </strong>{{competencia.habilidade}}</li>
                                            <li><strong>Atitude: </strong>{{competencia.atitude}}</li>
                                        </ul>
                                    </li>
                                </ol>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                    <b-thead head-variant="light">
                        <b-tr>
                            <b-th colspan="6">AVALIAÇÃO: </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-td colspan="6">{{item.avaliacao}}
                                <br>
                                <ol>
                                    <li>filipe
                                        <ul>
                                            <li>competencia</li>
                                            <li>competencia</li>
                                        </ul>
                                    </li>
                                </ol>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                    <b-thead head-variant="light">
                        <b-tr>
                            <b-th colspan="6">PRÁTICAS: </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-td colspan="6">{{item.praticas}}</b-td>
                        </b-tr>
                    </b-tbody>
                    <b-thead head-variant="light">
                        <b-tr>
                            <b-th colspan="6">BIBLIOGRAFIA BÁSICA: </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-td colspan="6">{{item.bibliografia_basica}}</b-td>
                        </b-tr>
                    </b-tbody>
                    <b-thead head-variant="light">
                        <b-tr>
                            <b-th colspan="6">BIBLIOGRAFIA COMPLEMENTAR: </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-td colspan="6">{{item.bibliografia_complementar}}</b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple> 
            </div>
            
        </div>

        <!-- 4.3. Plano de realização do Estágio Curricular -->
        <div class="row mb-5 mt-5">
            <h5>4.3. Plano de realização do Estágio Curricular.</h5>
            <hr>
            <p>{{formulario_especifico.item_4_3}}</p>
        </div>

        <!-- 4.4. Critérios e Mecanismos de Aproveitamento de Conhecimentos e Experiências Anteriores -->
        <div class="row mb-5 mt-5">
            <h5>4.4. Critérios e Mecanismos de Aproveitamento de Conhecimentos e Experiências Anteriores.</h5>
            <hr>
            <itemInput :showMode="true" forms="uninove_geral" code="item_4_4" titulo="4.4. Critérios e Mecanismos de Aproveitamento de Conhecimentos e Experiências Anteriores" v-model="formulario_geral.item_4_4" @valorEmitido="log"></itemInput>
        </div>

        <!-- 4.5. Procedimentos, Mecanismos e Gestão da Avaliação do Processo Ensino-Aprendizagem -->
        <div class="row mb-5 mt-5">
            <h5>4.5. Procedimentos, Mecanismos e Gestão da Avaliação do Processo Ensino-Aprendizagem.</h5>
            <hr>
            <itemInput :showMode="true" forms="uninove_geral" code="item_4_5" titulo="4.5. Procedimentos, Mecanismos e Gestão da Avaliação do Processo Ensino-Aprendizagem" v-model="formulario_geral.item_4_5" @valorEmitido="log"></itemInput>
        </div>

        <!-- 4.6 Critérios de avaliação da aprendizagem -->
        <div class="row mb-5 mt-5">
            <h5>4.6 Critérios de avaliação da aprendizagem.</h5>
            <hr>
            <p>{{formulario_especifico.item_4_6}}</p>
        </div>

        <!-- 4.6.1 Estratégias de acompanhamento e superação de dificuldades -->
        <div class="row mb-5 mt-5">
            <h5>4.6.1 Estratégias de acompanhamento e superação de dificuldades.</h5>
            <hr>
            <itemInput :showMode="true" forms="uninove_geral" code="item_4_6_1" titulo="4.6.1 Estratégias de acompanhamento e superação de dificuldades" v-model="formulario_geral.item_4_6_1" @valorEmitido="log"></itemInput>
        </div>

        <!-- 4.6.2 Estratégias de recuperação -->
        <div class="row mb-5 mt-5">
            <h5>4.6.2 Estratégias de recuperação.</h5>
            <hr>
            <p>{{formulario_especifico.item_4_6_2}}</p>
        </div>

        <!-- 4.6.3 Promoção/Pendência -->
        <div class="row mb-5 mt-5">
            <h5>4.6.3 Promoção/Pendência</h5>
            <hr>
            <p>{{formulario_especifico.item_4_6_3}}</p>
        </div>

        <!-- 4.7. Regras de Trancamento -->
        <!-- <div class="row mb-5 mt-5">
            <h5>4.7. Regras de Trancamento.</h5>
            <hr>
            <p>{{formulario_geral.item_4_7}}</p>
        </div> -->

        <!-- 4.8 Descrição do funcionamento dos serviços de apoio psicopedagógico e atendimento educacional especializado -->
        <div class="row mb-5 mt-5">
            <h5>4.8 Descrição do funcionamento dos serviços de apoio psicopedagógico e atendimento educacional especializado.</h5>
            <hr>
            <p>{{formulario_especifico.item_4_8}}</p>
        </div>

        <!-- 4.9 Estágio supervisionado -->
        <div class="row mb-5 mt-5">
            <h5>4.9 Estágio supervisionado.</h5>
            <hr>
            <p>{{formulario_especifico.item_4_9}}</p>
        </div>


        <div class="row mb-5 mt-5">
            <h2>5. Instalações e equipamentos.</h2>
        </div>

        <div class="row mb-5 mt-5">
            <h4>5.1 Salas de aula (apenas se houver oferta na sede)..</h4>
            <hr>
            <p>{{formulario_geral.item_5_1}}</p>
        </div>

        <div class="row mb-5 mt-5">
            <h4>5.2 Relação de laboratórios (Repetir para cada um).</h4>
            <hr>
            <div v-for="item in formulario_especifico.item_5_2" :key="item">
                <b-table-simple small caption-top responsive>
                    <colgroup><col><col></colgroup>
                    <colgroup><col><col><col></colgroup>
                    <colgroup><col><col></colgroup>
                    <b-thead>
                        <b-tr>
                            <b-th colspan="5">{{item.nome_lab}}</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-td colspan="2">Capacidade: {{item.capacidade}}</b-td>
                            <b-td colspan="3">Quantidade: {{item.quantidade}}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-td colspan="5">Finalidade: {{item.finalidade}}</b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple> 
            </div>
            <b-button size="sm" variant="primary" @click="add_item_5_2()">
                Add
            </b-button> 


            <table>
                <tr>Nome lab</tr>
                <tr>
                    <td>Quantidade</td>
                    <td>Capacidade</td>
                </tr>
                <tr>
                    <td>Finalidade</td>
                </tr>
            </table>
            <p>{{formulario_geral.item_5_2}}</p>
        </div>

        <div class="row mb-5 mt-5">
            <h4>5.3 Materiais, equipamentos, ferramentas e outros insumos para atividades práticas:</h4>
            <hr>
            <p>{{formulario_geral.item_5_3}}</p>
        </div>

        <div class="row mb-5 mt-5">
            <h4>5.4 Outras instalações (Repetir para cada uma)</h4>
            <hr>
            <p>{{formulario_geral.item_5_4}}</p>
        </div>

        <div class="row mb-5 mt-5">
            <h4>5.5 Recursos de acessibilidade (repetir para cada uma)</h4>
            <hr>
            <p>{{formulario_geral.item_5_5}}</p>
        </div>

        <div class="row mb-5 mt-5">
            <h4>5.6 Biblioteca</h4>
            <hr>
            <p>{{formulario_geral.item_5_6}}</p>
        </div>

        <div class="row mb-5 mt-5">
            <h4>5.7 Acervo bibliográfico do curso.</h4>
            <div>
                <b-table striped small :items="formulario_geral.item_5_7.items" :fields="formulario_geral.item_5_7.fields"></b-table>
            </div>
        </div>

        <div class="row mb-5 mt-5">
            <h2>6. Corpo Docente e Tutorial, Coordenador Pedagógico e Pessoal Técnico-Administrativo. </h2>
            <hr>
            <p>{{formulario_geral.item_6_1}}</p>
        </div>

        <div class="row mb-5 mt-5">
            <h4>6.2. Corpo Tutorial (exclusivo para curso na modalidade a distância). </h4>
            <hr>
            <div>
                tabela
            </div>
            <div>
                texto
            </div>
        </div>

        <div class="row mb-5 mt-5">
            <h4>6.3. Coordenador Pedagógico do Curso.</h4>
            <hr>
            <p>{{formulario_geral.item_6_3}}</p>
        </div>

        <div class="row mb-5 mt-5">
            <h4>6.4. Pessoal Técnico Administrativo. (Repetir para cada um)</h4>
            <hr>
            <p>{{formulario_geral.item_6_4}}</p>
        </div>

        <div class="row mb-5 mt-5">
            <h2>7. Diplomas e Certificados Expedidos aos que concluíram o Curso Técnico ou certificação intermediária, conforme Diretrizes curriculares. </h2>
            <hr>
        </div>

        <div class="row mb-5 mt-5">
            <h2>8. Descrição do Ambiente Virtual de Aprendizagem. </h2>
            <hr>
        </div>

        <div class="row mb-5 mt-5">
            <h2>9. Anexos</h2>
            <hr>
        </div>


        <!-- {{formulario_especifico}}
        <hr>
        {{formulario_geral}} -->

    </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import 'firebase/database';
import 'firebase/firestore';
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import uploadImgInput from '@/views/teste_nadir/components/upload_img.vue'
import itemInput from '@/views/teste_nadir/components/itemInput.vue'
import richTextInput from '@/views/teste_nadir/components/richTextInput.vue'


// Inicializando o Firebase
var app = firebase.app();
firebase.analytics();
var db = firebase.firestore(app);

export default {
    components: { 
        richTextInput,
        itemInput,
        uploadImgInput
    },
    data() {
        return {
            responsavel: '',
            nome_curso: '',
            eixo_curso: '',
            campus_oferta: '',
            formulario_especifico: [],
            formulario_geral: [],
            curso_selecionado: 'Selecione um Curso',
            campus_selecionado: 'Selecione um Campus',
            itens_visiveis: ['item_1_1', 'item_1_2', 'item_1_3', 'item_1_4', 'item_1_5_1', 
            'item_1_5_2', 'item_2', 'item_2_1', 'item_2_2', 'item_3', 'item_3_1', 'item_4_1', 
            'item_4_10', 'item_4_2', 'ite_m_42_1', 'item_4_3', 'item_4_4', 'item_4_5', 'item_4_6', 
            'item_4_6_1', 'item_4_6_2', 'item_4_6_3', 'item_4_7', 'item_4_8', 'item_4_9_1', 
            'item_4_9_2', 'item_4_9_3', 'item_5_1', 'item_5_2', 'item_5_3', 'item_5_4', 
            'item_5_5', 'item_5_6', 'item_5_7', 'item_6_1', 'item_6_2', 'item_6_3', 'item_6_4', 
            'item_7_1', 'item_7_2', 'item_8', 'item_9', 'item_9_1', 'item_9_2', 'item_9_3', 'item_10'],
            selected_itens: ['item_1_1', 'item_1_2', 'item_1_3', 'item_1_4', 'item_1_5_1', 
            'item_1_5_2', 'item_2', 'item_2_1', 'item_2_2', 'item_3', 'item_3_1', 'item_4_1', 
            'item_4_10', 'item_4_2', 'ite_m_42_1', 'item_4_3', 'item_4_4', 'item_4_5', 'item_4_6', 
            'item_4_6_1', 'item_4_6_2', 'item_4_6_3', 'item_4_7', 'item_4_8', 'item_4_9_1', 
            'item_4_9_2', 'item_4_9_3', 'item_5_1', 'item_5_2', 'item_5_3', 'item_5_4', 
            'item_5_5', 'item_5_6', 'item_5_7', 'item_6_1', 'item_6_2', 'item_6_3', 'item_6_4', 
            'item_7_1', 'item_7_2', 'item_8', 'item_9', 'item_9_1', 'item_9_2', 'item_9_3', 'item_10']
        }
    },

    methods:{
        toPdf(){
            var docDefinition = {
            content: [
                    {
                        text: 'Plano de curso',
                        style: 'header',
                        alignment: 'center'
                    },
                    {
                        text: 'CURSO TÉCNICO EM',
                        style: 'header',
                        alignment: 'center'
                    },
                    {
                        text: 'EIXO TECNOLÓGICO: GESTÃO E NEGÓCIOS',
                        style: 'header',
                        alignment: 'center'
                    },
                    {
                        text: 'SÃO PAULO',
                        style: 'header',
                        alignment: 'center'
                    },
                    {
                        text: '2022',
                        style: 'header',
                        alignment: 'center'
                    },
                    {
                        toc: {
                            title: {text: 'INDEX', style: 'header'}
                        }
                    },

                    // using a { text: '...' } object lets you set styling properties
                    { text: this.formulario_geral.item_1_3, fontSize: 15 },

                    // if you set the value of text to an array instead of a string, you'll be able
                    // to style any part individually
                    {
                        text: [
                            'This paragraph is defined as an array of elements to make it possible to ',
                            { text: 'restyle part of it and make it bigger ', fontSize: 15 },
                            'than the rest.'
                        ]
                    }
                ]
            };

            pdfMake.createPdf(docDefinition).open();
        },

        convertToPlain(elem) {
            console.log(document.getElementById(elem).innerText)
            return document.getElementById(elem).innerText
        },

        add_item_5_2(){
            this.formulario_especifico.item_5_2.push({nome_lab: 'laboratorio', capacidade: '10', finalidade: 'Descrição do funcionamento dos serviços de apoio psicopedagógico e atendimento educacional especializado. Descrição do funcionamento dos serviços de apoio psicopedagógico e atendimento educacional especializado.Descrição do funcionamento dos serviços de apoio psicopedagógico e atendimento educacional especializado.Descrição do funcionamento dos serviços de apoio psicopedagógico e atendimento educacional especializado. ', quantidade: '2'})
        },

        log(value){
            console.log('Item atualizado', value)
        },

    },
    mounted() {
        if(this.$route.params.doc){
            // Requisitando dados específicos do curso
            db.collection("/nadir").doc(this.$route.params.doc)
            .get()
            .then((doc) => {
                // console.log(doc.data())
                this.formulario_especifico = doc.data().formulario_especifico
                this.nome_curso = doc.data().nome_curso
                this.responsavel = doc.data().responsavel
                this.campus_oferta = doc.data().campus_oferta
                this.eixo_curso = doc.data().eixo_curso

                // Requisitando dados gerais do curso
                db.collection("/nadir").doc('forms_geral')
                .get()
                .then((doc) => {
                    this.formulario_geral = doc.data().documento_completo
                }).catch((error) => {
                    console.log("Error getting documents: ", error);
                });
                
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
        } else {
            alert('Nenhum curso encontrado para o ID especificado')
        }
    }
}
</script>

<style>
p{
    margin: unset !important;
}

</style>