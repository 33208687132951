<template>
    <div class="row">
        <!-- label-->
        <div class="col-10" style="align-self: end;">
            <h6><strong>{{titulo}}</strong></h6>
        </div>

        <!-- Botão Editar -->
        <div v-if="!showMode" class="col" style="text-align: right;">
            <b-button v-b-modal="'modal-'+code" variant="link" style="text-decoration: none"> 
            <b-icon icon="pencil-square"></b-icon>Editar</b-button>
        </div>

        <!-- Texto atual -->
        <div class="col-12">
            <p v-if="value" style="text-align: justify; white-space: pre-line;">{{value}}</p>
            <p v-else class="sem-texto">
                Não há nenhum texto cadastrado. Clique em editar para inserir.
            </p>
        </div>
        
        <!-- Modal acionado -->
        <b-modal size="lg" :id="'modal-'+code" :title="titulo" @show="resetModal" @hidden="resetModal">
            <b-form-group label="" :label-for="'modal-input-'+code">
                <b-form-input v-on:keyup.enter="changeFirebaseValue" v-if="type == 'short-text'" :id="'modal-input-'+code" v-model="valor_provisorio" rows="3" max-rows="20"></b-form-input>
                <b-form-textarea v-else :id="'modal-input-'+code" v-model="valor_provisorio" rows="3" max-rows="20"></b-form-textarea>
            </b-form-group>     

            <template #modal-footer>
                <!-- <b-button size="sm" variant="link" @click="aplicarRegra()">
                    Aplicar Regra
                </b-button>   -->
                <b-button size="sm" variant="primary" @click="changeFirebaseValue()">
                    Salvar
                </b-button>  
            </template> 
        </b-modal>

    
    </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/analytics";
import 'firebase/firestore';

// Inicializando o Firebase
var app = firebase.app();
firebase.analytics();
var db = firebase.firestore(app);

export default {
    name: 'itemInput',
    props: ['titulo', 'code', 'value', 'id_curso', 'id_campus', 'forms', 'type', 'showMode'],
    data() {
        return {
            valor_provisorio: ''
        }
    },
    methods: {
        aplicarRegra(){
            
            this.valor_provisorio = this.valor_provisorio.replaceAll('\\begin{enumerate}', '') 
            this.valor_provisorio = this.valor_provisorio.replaceAll('\\end{enumerate}', '')
            this.valor_provisorio = this.valor_provisorio.replaceAll('\\newline', '')
            this.valor_provisorio = this.valor_provisorio.replaceAll('\\textbf{', '')
            this.valor_provisorio = this.valor_provisorio.replaceAll('\\begin{itemize}', '') 
            this.valor_provisorio = this.valor_provisorio.replaceAll('\\end{itemize}', '') 
            this.valor_provisorio = this.valor_provisorio.replaceAll('\\item', '')
            
            this.valor_provisorio = this.valor_provisorio.replaceAll('}', '')
            this.valor_provisorio = this.valor_provisorio.replaceAll('\\', '')





            // this.valor_provisorio = this.valor_provisorio.replaceAll('<li>', '\\item ')
            // this.valor_provisorio = this.valor_provisorio.replaceAll('•', '\\item ')
            // this.valor_provisorio = this.valor_provisorio.replaceAll('●', '\\item ')
            // this.valor_provisorio = this.valor_provisorio.replaceAll('<li class="ql-align-justify">', '\\item ')
            // this.valor_provisorio = this.valor_provisorio.replaceAll('</li>', ' ')
            // this.valor_provisorio = this.valor_provisorio.replaceAll('<p>', '')
            // this.valor_provisorio = this.valor_provisorio.replaceAll('</p>', ' ')
            // this.valor_provisorio = this.valor_provisorio.replaceAll('<ul>', ' ')
            // this.valor_provisorio = this.valor_provisorio.replaceAll('</ul>', ' ')
            // this.valor_provisorio = this.valor_provisorio.replaceAll('<ol>', '\\begin{enumerate} ')
            // this.valor_provisorio = this.valor_provisorio.replaceAll('</ol>', '\\end{enumerate} ')
            // this.valor_provisorio = this.valor_provisorio.replaceAll('<br>', '\\newline ')
            // this.valor_provisorio = this.valor_provisorio.replaceAll('%', '')
            // this.valor_provisorio = this.valor_provisorio.replaceAll("%", "\\%")
            // this.valor_provisorio = this.valor_provisorio.replaceAll("$", "\\$")
            // this.valor_provisorio = this.valor_provisorio.replaceAll("&nbsp;", "")
            // this.valor_provisorio = this.valor_provisorio.replaceAll('<p class="ql-align-center">', "")
            // this.valor_provisorio = this.valor_provisorio.replaceAll('<p class="ql-align-justify">', "")
            // this.valor_provisorio = this.valor_provisorio.replaceAll('</em>', '')
            // this.valor_provisorio = this.valor_provisorio.replaceAll('<em>', '')
            // this.valor_provisorio = this.valor_provisorio.replaceAll('<strong>', '\\textbf{')
            // this.valor_provisorio = this.valor_provisorio.replaceAll('</strong>', '}')
            

            
        },

        resetModal() {
            // Volta o modal ao seu valor inicial ou ao novo valor recebido
            this.valor_provisorio = this.value
        },

        changeFirebaseValue(){
            let doc_name
            let path_item
            let colection

            if(this.forms == 'uninove_geral'){
                console.log('Alterando formulário Geral UNINOVE')
                doc_name = 'forms_geral'
                path_item = {[`documento_completo.${this.code}`]: this.valor_provisorio}
                colection = '/nadir'
            } else if(this.forms == 'campus_especifico') {
                console.log(`Alterando dados do campus ${this.id_campus}`)
                doc_name = this.id_campus
                path_item = {[`${this.code}`]: this.valor_provisorio}
                colection = '/campus_uninove'
            } else if(this.forms == 'itens_gerais_curso_especifico') {
                console.log(`Alterando dados do curso ${this.id_curso}`)
                doc_name = this.id_curso
                path_item = {[`${this.code}`]: this.valor_provisorio.toString()}
                colection = '/nadir'
            } else {
                console.log(`Alterando formulário específico de código ${this.id_curso}`)
                doc_name = this.id_curso
                path_item = {[`formulario_especifico.${this.code}`]: this.valor_provisorio}
                colection = '/nadir'
            }
            db.collection(colection).doc(doc_name)
            .update(path_item)
            .then(() => {
                alert('Alterações salvas com sucesso')
                console.log("Document successfully updated!");
                this.$emit('valorEmitido', this.valor_provisorio)
                this.$bvModal.hide('modal-'+ this.code)
            })
            .catch((error) => {
                alert('Erro, verifique o console')
                console.error("Error updating document: ", error);
            });
        }
    }
}
</script>

<style scoped>
ul, ol{
    list-style: auto;
}

input{
    width: -webkit-fill-available;
}

.sem-texto{
    padding: 10px;
    font-style: italic;
    background: #e8e8e8;
}
</style>
