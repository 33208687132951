<template>
    <div class="row">
        <!-- label-->
        <div class="col-10" style="align-self: end;">
            <h6><strong>{{titulo}}</strong></h6>
        </div>

        <!-- Botão Editar -->
        <div v-if="!showMode" class="col" style="text-align: right;">
            <b-button v-b-modal="'modal-'+code" variant="link" style="text-decoration: none"> 
            <b-icon icon="pencil-square"></b-icon>Editar</b-button>
        </div>

        <!-- Texto atual -->
        <div class="col-12">
            <div v-if="value" v-html="value" class="row" style="text-align: justify;"></div>
            <p v-else class="sem-texto">
                Não há nenhum texto cadastrado. Clique em editar para inserir.
            </p>
        </div>
        
        <!-- Modal acionado -->
        <b-modal size="lg" :id="'modal-'+code" :title="titulo" @show="resetModal" @hidden="resetModal">
            <!-- <b-form-group label="" :label-for="'modal-input-'+code"> -->
                <!-- <b-form-textarea :id="'modal-input-'+code" v-model="valor_provisorio" rows="3" max-rows="20"></b-form-textarea> -->
                <vue-editor :editor-toolbar="customToolbar" :id="'modal-input-'+code" v-model="valor_provisorio" />
            <!-- </b-form-group>      -->

            <template #modal-footer>
                <b-button size="sm" variant="primary" @click="changeFirebaseValue()">
                    Salvar
                </b-button>  
            </template> 
        </b-modal>

    </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/analytics";
import 'firebase/firestore';
import { VueEditor } from "vue2-editor";

// Inicializando o Firebase
var app = firebase.app();
firebase.analytics();
var db = firebase.firestore(app);

export default {
    name: 'richTextInput',
    components: { VueEditor },
    props: ['titulo', 'code', 'value', 'id_curso', 'forms', 'showMode'],
    data() {
        return {
            valor_provisorio: '',
            customToolbar: [
                [{ header: [false, 2, 3] }],
                // [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }], 
                // ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                // ["blockquote"]
            ]
        }
    },
    methods: {
        resetModal() {
            // Volta o modal ao seu valor inicial ou ao novo valor recebido
            this.valor_provisorio = this.value
        },

        changeFirebaseValue(){
            let doc_name
            let path_item

            if(this.forms == 'uninove_geral'){
                console.log('Alterando formulário Geral UNINOVE')
                doc_name = 'forms_geral'
                path_item = {[`documento_completo.${this.code}`]: this.valor_provisorio}
            } else {
                console.log(`Alterando formulário específico de código ${this.id_curso}`)
                doc_name = this.id_curso
                path_item = {[`formulario_especifico.${this.code}`]: this.valor_provisorio}
            }
            db.collection("/nadir").doc(doc_name)
            .update(path_item)
            .then(() => {
                alert('Alterações salvas com sucesso')
                console.log("Document successfully updated!");
                this.$emit('valorEmitido', this.valor_provisorio)
                this.$bvModal.hide('modal-'+ this.code)
        
            })
            .catch((error) => {
                alert('Erro, verifique o console')
                console.error("Error updating document: ", error);
            });
        }
    }
}
</script>

<style scoped>
input{
    width: -webkit-fill-available;
}

.sem-texto{
    padding: 10px;
    font-style: italic;
    background: #e8e8e8;
}
</style>
